// import Swiper, {Navigation, Pagination} from "swiper";
import Swiper from "swiper";
import {Navigation, Pagination} from 'swiper/modules';
import "sources/components/Slider/slider";

import './BlockBanner.scss';
// import "swiper/modules/pagination/pagination.scss";
// import "swiper/modules/pagination/pagination.min.css";
import 'swiper/swiper-bundle.css';

export class BlockBanner {
    init() {
        Swiper.use([Navigation, Pagination]);

        const swiper = new Swiper('.js-bannerSlider', {
            loop: true,
            uniqueNavElements: false,
            pagination: {
                el: ".js-bannerSliderWrap .swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    // return '<span class="' + className + '">' + (index + 1) + '</span>';
                    return `<span class="swiper-pagination-bullet ${className}"><span class="pagination-bullet-inner"></span></span>`;
                },
            },
            // navigation: {
            //     nextEl: '.js-bannerSlider .js-swiper-button-next',
            //     prevEl: '.js-bannerSlider .js-swiper-button-prev',
            // },
        });

        const buildSwiperSlider = sliderElm => {
            const categoryId = sliderElm.getAttribute("data-categoryslider");
            // return new Swiper(`#${sliderElm.id}`, {
            //     navigation: {
            //         nextEl: `.swiper-button-next-${sliderIdentifier}`,
            //         prevEl: `.swiper-button-prev-${sliderIdentifier}`
            //     },
            //     pagination: {
            //         el: `.swiper-pagination-${sliderIdentifier}`,
            //         type: 'progressbar',
            //     },
            // });
            const selector = `[data-categoryslider="${categoryId}"]`;
            const selectorWrap = `[data-categorysliderwrap="${categoryId}"]`;
            console.log(selector);
            return new Swiper(selector, {
                loop: true,
                uniqueNavElements: false,

                // pagination: {
                //     el: ".js-categorySliderWrap .swiper-pagination",
                //     clickable: true,
                //     renderBullet: function (index, className) {
                //         // return '<span class="' + className + '">' + (index + 1) + '</span>';
                //         return `<span class="swiper-pagination-bullet ${className}"><span class="pagination-bullet-inner"></span></span>`;
                //     },
                // },
                breakpoints: {
                    // when window width is >= 320px
                    // 320: {
                    //     slidesPerView: 2,
                    //     spaceBetween: 20
                    // },
                    // // when window width is >= 480px
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 4,
                        spaceBetween: 10
                    }
                },

                navigation: {
                    nextEl: `${selectorWrap} .js-swiper-button-next`,
                    prevEl: `${selectorWrap} .js-swiper-button-prev`,
                },
            });
        }

        const categorySliders = document.querySelectorAll('[data-categoryslider]');
        categorySliders.forEach(sliderEl => {
            const slider = buildSwiperSlider(sliderEl);
            slider.init();
        });

        if (Array.isArray(swiper)) {
            swiper.forEach((s => s.init()));
        } else {
            swiper.init();
        }

        // if (Array.isArray(swiperCat)) {
        //     swiperCat.forEach((s => s.init()));
        // } else {
        //     swiperCat.init();
        // }
    }
}
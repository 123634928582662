import {publicLayout} from "sources/entries/publicLayout";
import {BlockBanner} from "../../components/Blocks/Banner/BlockBanner";
import "./mainPage.scss";
console.log("main page");

function initPage() {
    const mainBanner = new BlockBanner();
    mainBanner.init();
}

publicLayout.register();

document.addEventListener("DOMContentLoaded", initPage);